import { SAVED_FORM } from 'shared/constants/forms';
import { mapQuestionsWithAnswers } from './mapQuestionsWithAnswers';
import {
  FormattedAnswers,
  QuestionType,
  QuestionTypeEnum
} from 'shared/components/presentational/Form/types';

export const saveAndPersistForm = (
  _id: string,
  questions: QuestionType[],
  answers: FormattedAnswers,
  recaptchaValue: string
) => {
  const answerableQuestions = questions.filter(
    question => question.type !== QuestionTypeEnum.SEPARATOR
  );
  const form = mapQuestionsWithAnswers(answerableQuestions, answers);
  sessionStorage.setItem(SAVED_FORM, JSON.stringify({ form, recaptchaValue }));
};
