export default function formatFilesToAttachments(files) {
  return files.map(file => {
    return {
      name: file.name,
      mime: file.mime,
      data: {
        type: 'base64',
        value: file.base64
      }
    };
  });
}
