import { WILDCARD_PATH } from 'shared/blocks/smart-faq/constants';

export const FAQ_WIDGET_URL =
  'https://x1-webapp.canal-overseas.com/ns/dev/faq-dev/searchWidget.js';

export const FAQ_ALL_RESULTS_URL_TEMPLATE = `//{hostname}/{bigram}/${WILDCARD_PATH}/resultats-recherche?faqSearchText={{search_text}}`;

export const DEFAULT_ERROR = 'blocks.form.mailError';
export const FAQ_ALL_RESULTS = 'blocks.form.faqAllResults';
export const FAQ_NO_RESULTS = 'blocks.form.faqNoResults';

export const FAQ_RESULTS_ID = 'blocks.form.faqResultsId';

export const ALLOWED_ATTACHMENTS_MIME_TYPES =
  'application/pdf,image/jpeg,image/png,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const MAX_ATTACHMENTS_SIZE = 6 * 1000 * 1000; // 6MB
