import React, { PureComponent, ReactNode } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FieldProps } from 'formik';

import Recaptcha from '../Recaptcha';
import FieldHint from '../FieldHint';
import translateStaticYupError, {
  Error
} from '../helpers/translateStaticYupError';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

class RecaptchaField extends PureComponent<FieldProps<any> & WithTranslation> {
  private handleVerify = (response: string): void => {
    const { onBlur, name } = this.props.field;
    const { setFieldValue, setTouched } = this.props.form;

    setFieldValue(name, response);
    setTouched({ [name]: true });
    // @ts-ignore
    onBlur();
  };

  private handleErrorOrExpire = (): void => {
    const { onBlur, name } = this.props.field;
    const { setFieldValue, setTouched } = this.props.form;

    setFieldValue(name, false);
    setTouched({ [name]: true });
    // @ts-ignore
    onBlur();
  };

  public render(): ReactNode {
    const { form, field, t, ...props } = this.props;
    const error = form.touched[field.name] && form.errors[field.name];
    const errorI18n = translateStaticYupError(error as Error, t);

    return (
      <Wrapper>
        <Recaptcha
          {...form}
          {...field}
          {...props}
          onVerify={this.handleVerify}
          onExpire={this.handleErrorOrExpire}
          onError={this.handleErrorOrExpire}
        />
        <FieldHint error={errorI18n} isRequired />
      </Wrapper>
    );
  }
}

export default withTranslation()(RecaptchaField);
