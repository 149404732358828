import { MAX_ATTACHMENTS_SIZE } from '../constants';
import calculateBase64Size from './calculateBase64Size';

export default function hasFilesExceededLimit(files) {
  let totalBase64Size = 0;
  files.forEach(file => {
    totalBase64Size += calculateBase64Size(file.size);
  });
  return totalBase64Size > MAX_ATTACHMENTS_SIZE;
}
