import { SAVED_FORM } from 'shared/constants/forms';
import { isV2Template } from './isV2Template';
import {
  FormattedAnswers,
  QuestionType,
  QuestionTypeEnum,
  QuestionTypeWithAnswer,
  Template
} from 'shared/components/presentational/Form/types';
import { readSessionStorage } from 'shared/helpers/sessionStorage/readSessionStorage';

export default function generateInitialValues(
  questions: QuestionType[],
  template?: Template
) {
  const formAnswers: FormattedAnswers = {};

  const savedForm = readSessionStorage(SAVED_FORM);
  if (isV2Template(template) && savedForm) {
    savedForm.form.forEach((question: QuestionTypeWithAnswer) => {
      if (question.type !== QuestionTypeEnum.SEPARATOR) {
        formAnswers[question.name] = question.value;
      }
    });
  }

  return questions.reduce((acc, question) => {
    if (['checkboxes', 'file'].includes(question.type)) {
      return {
        ...acc,
        [question.name]: formAnswers[question.name] || []
      };
    }
    return {
      ...acc,
      [question.name]: formAnswers[question.name] || ''
    };
  }, {});
}
