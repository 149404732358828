import {
  FormattedAnswers,
  QuestionType
} from 'shared/components/presentational/Form/types';

export const mapQuestionsWithAnswers = (
  questions: QuestionType[],
  answers: FormattedAnswers
) =>
  questions.map(question => ({
    ...question,
    value: answers[question.name]
  }));
