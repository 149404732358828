/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import get from 'lodash/get';

// returns answers from any form
export default state =>
  Object.values(get(state, `forms.answers.byFormId`, {})).reduce(
    (acc, answers) => ({ ...acc, ...answers }),
    {}
  );
