export const readSessionStorage = (key: string) => {
  if (typeof window === 'undefined') {
    return null;
  }
  const item = sessionStorage.getItem(key);
  if (!item) {
    return null;
  }

  try {
    return JSON.parse(item);
  } catch {
    return item;
  }
};
