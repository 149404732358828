import React, { useCallback, useEffect } from 'react';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3';

import ConfigHelperHOC from '../ConfigHelperHOC';

interface Props {
  value: boolean;
  publicConfig: any; // @FIXME type ConfigHelperHOC
  setFieldValue: (name: string, token: string) => void;
  setTouched: (fields: { [field: string]: boolean }) => void;
  name: string;
}

const Recaptcha = ({
  publicConfig,
  setFieldValue,
  setTouched,
  name
}: Props) => {
  useEffect(() => {
    loadReCaptcha(publicConfig.api.services.recaptcha);
  }, [publicConfig.api.services.recaptcha]);

  const verifyCallback = useCallback(
    recaptchaToken => {
      setFieldValue(name, recaptchaToken);
      setTouched({ [name]: true });
    },
    [name, setFieldValue, setTouched]
  );

  return (
    <ReCaptcha
      sitekey={publicConfig.api.services.recaptcha}
      verifyCallback={verifyCallback}
    />
  );
};

export default ConfigHelperHOC(Recaptcha);
