/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import { createSelector } from 'reselect';

import getAnswers from './getAnswers';
import getQuestions from './getQuestions';
import formatAnswers from '../helpers/formatAnswersAndAttachments';

export default createSelector(getAnswers, getQuestions, formatAnswers);
