import * as yup from 'yup';

const applySettingsRules = ({ settings = {}, type }, schema) =>
  Object.keys(settings).reduce((acc, setting) => {
    switch (setting) {
      case 'min':
        return acc.min(settings.min);
      case 'max':
        return acc.max(settings.max);
      case 'choices': {
        if (type === 'checkboxes') {
          return acc;
        }
        const { choices } = settings;
        return acc.matches(
          new RegExp(
            Object.keys(choices).reduce(
              (exp, choice, i) =>
                `${exp}${choice}${
                  i === Object.keys(choices).length - 1 ? ')' : '|'
                }`, // CLOSE or OR expression
              '('
            ) // OPEN expression
          ),
          { excludeEmptyString: true }
        );
      }
      default:
        return acc;
    }
  }, schema);

const applyIsRequired = ({ isRequired }, schema) =>
  isRequired ? schema.required() : schema;

const applyIsNullable = ({ type }, schema) =>
  type === 'select' ? schema.nullable() : schema;

const createBaseSchema = question => {
  switch (question.type) {
    case 'checkboxes':
      return yup.array();
    case 'email':
      return yup.string().email();
    case 'phone':
      return yup.string().isNumber();
    case 'textarea':
    case 'text':
    case 'select':
    case 'radios':
    case 'file':
    default:
      return yup.string();
  }
};

const createCaptchaSchema = () => yup.string().captcha();

export const createValidatedSchema = question =>
  [applyIsRequired, applyIsNullable, applySettingsRules].reduce(
    (schema, applyFunc) => applyFunc(question, schema),
    createBaseSchema(question)
  );

/*
 * Creates a schema, looping on each question
 */
export default ({ questions, withMailing }) =>
  yup.object().shape(
    questions.reduce((shape, question) => {
      const schema = {
        ...shape,
        [question.name]: createValidatedSchema(question)
      };

      if (withMailing) {
        schema.recaptcha = createCaptchaSchema();
      }

      return schema;
    }, {})
  );
