import React, { Fragment, PureComponent } from 'react';
import styled from 'styled-components';
import remove from 'lodash/remove';
import get from 'lodash/get';

import {
  Input,
  Textarea,
  Radios,
  Checkboxes,
  Select,
  Hint,
  FieldHint as BaseFieldHint,
  Label
} from 'shared/components/presentational/Form';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import { FAQ_RESULTS_ID } from '../../constants';
import { FONT_FAMILIES } from 'shared/constants/theme';
import translateStaticYupError from '../../../../components/presentational/Form/helpers/translateStaticYupError';
import FileField from './FileField';

const Wrapper = styled.div`
  position: relative;
  display: block;

  &:not(:first-child):not(:last-child) {
    margin: 40px 0;

    ${mediaQueries.toPhablet`
      margin: 25px 0;
    `};
  }
`;

const Suggestion = styled.div`
  font-family: ${FONT_FAMILIES.base};
`;

const FieldHint = styled(BaseFieldHint)`
  position: absolute;
  left: 0;
  top: 100%;
`;

/**
 * Callbacks function that are passed to `withField` HOC to allow
 * component specific props formatting
 *
 * eg: Select component need formatted options props that do not match API returned format
 */

const basicPropsTransformer = ({ form, field, ...props }) => ({
  form,
  field,
  ...props,
  value: props.value ?? '',
  error: form.touched[field.name] && form.errors[field.name]
});

const selectPropsTransformer = ({ form, field, ...props }) => ({
  form,
  field,
  ...props,
  value: props.value ?? '',
  error: form.touched[field.name] && form.errors[field.name],
  onChange: data => form.setFieldValue(field.name, data.value)
});

const checkboxesPropsTransformer = ({ form, field, ...props }) => ({
  form,
  field,
  ...props,
  value: props.value ?? '',
  error: form.touched[field.name] && form.errors[field.name],
  onChange: data => {
    const prevValue = props.value || [];
    const { value } = data.target;
    if (prevValue.includes(value)) {
      form.setFieldValue(
        field.name,
        remove(prevValue, v => v !== value)
      );
    } else {
      form.setFieldValue(field.name, [...prevValue, value]);
    }
    form.setTouched({ [field.name]: true });
  }
});

/**
 * This is a map of `question.type` and Form's components
 */
const Types = {
  textarea: {
    fieldRenderer: props => <Textarea {...basicPropsTransformer(props)} />,
    hintPosition: 'field'
  },
  text: {
    fieldRenderer: props => (
      <Input type="text" {...basicPropsTransformer(props)} />
    ),
    hintPosition: 'field'
  },
  email: {
    fieldRenderer: props => (
      <Input type="email" {...basicPropsTransformer(props)} />
    ),
    hintPosition: 'field'
  },
  phone: {
    fieldRenderer: props => (
      <Input type="tel" {...basicPropsTransformer(props)} />
    ),
    hintPosition: 'field'
  },
  radios: {
    fieldRenderer: props => <Radios {...basicPropsTransformer(props)} />,
    hintPosition: 'field'
  },
  select: {
    fieldRenderer: props => <Select {...selectPropsTransformer(props)} />,
    hintPosition: 'field'
  },
  checkboxes: {
    fieldRenderer: props => (
      <Checkboxes {...checkboxesPropsTransformer(props)} />
    ),
    hintPosition: 'none' // we don't want error messages for checkbox fields
  },
  file: {
    fieldRenderer: props => <FileField {...props} />,
    hintPosition: 'label'
  }
};

/**
 * Fields
 *
 * This component takes care of returning the appropriate component according to `question.type`
 *
 */
export default class Question extends PureComponent {
  render() {
    const { props } = this;
    const { question, form, field, t } = props;
    const { hintPosition, fieldRenderer: Component } = get(
      Types,
      `[${question.type}]`,
      {}
    );

    if (!Component) {
      // eslint-disable-next-line
      console.warn(
        `Question type :${question.type} is not supported. Check Blocks/form/Field.js`
      );
      return false;
    }

    const error = form.touched[field.name] && form.errors[field.name];
    const errorI18n = translateStaticYupError(error, t);

    const showSuggestion = get(question, 'settings.suggestion', false);

    return (
      <Wrapper>
        {(question.label || question.subLabel) && (
          <Label
            name={question.name}
            label={question.label}
            subLabel={question.subLabel}
            error={errorI18n}
          >
            {hintPosition === 'label' && (
              <Hint error={errorI18n} isRequired={question.isRequired} />
            )}
          </Label>
        )}

        <Fragment>
          <Component
            {...form}
            {...field}
            {...props}
            error={error}
            placeholder={
              question.placeholder
                ? question.isRequired
                  ? question.placeholder + `${t('common.form.hint.required')}`
                  : question.placeholder
                : null
            }
            options={question.options}
          />

          {showSuggestion && (
            <Suggestion id={`${field.name}-${t(FAQ_RESULTS_ID)}`} />
          )}

          {hintPosition === 'field' && (
            <FieldHint error={errorI18n} isRequired={question.isRequired} />
          )}
        </Fragment>
      </Wrapper>
    );
  }
}
