/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2018 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import TYPES from './types';

export default (id, questions, answers) => ({
  type: TYPES.SAVE_FORM,
  payload: { id, questions, answers }
});
