import React, { useEffect } from 'react';
import axios from 'axios';
import { withFormik, Field } from 'formik';
import omit from 'lodash/omit';
import get from 'lodash/get';
import { compose, withState } from 'recompose';
import { withTranslation } from 'react-i18next';

import Button from 'shared/components/presentational/Button/Button';
import RecaptchaField from 'shared/components/presentational/Form/Fields/RecaptchaField';
import ApiError from 'shared/components/presentational/ApiError';
import ResponsiveHoc from 'shared/components/presentational/ResponsiveHOC/ResponsiveHoc';
import errorEnhancer from 'shared/helpers/form/errorEnhancer';
import { initScript } from 'shared/helpers/assets';
import createTemplate from 'shared/helpers/template/createTemplate';
import formatAnswers from '../helpers/formatAnswersAndAttachments';
import QuestionField from './components/QuestionField';
import validationSchemaFactory from '../validationSchemaFactory';
import {
  DEFAULT_ERROR,
  FAQ_WIDGET_URL,
  FAQ_ALL_RESULTS_URL_TEMPLATE,
  FAQ_ALL_RESULTS,
  FAQ_NO_RESULTS,
  FAQ_RESULTS_ID
} from '../constants';
import generateInitialValues from '../helpers/generateInitialValues';
import { isV2Template } from '../helpers/isV2Template';
import {
  Wrapper,
  Title,
  Subtitle,
  RecaptchaWrapper,
  CTA,
  CTAButton,
  Form,
  FieldWrapper,
  MandatoryFieldsMessage,
  RecaptchaMessage,
  FormContainer,
  HrSeparator
} from '../styles/components';
import { SAVED_FORM, FORM_CATEGORY } from 'shared/constants/forms';

const formikEnhancer = withFormik({
  mapPropsToValues: () => ({
    recaptcha: false
  }),
  mapPropsToStatus: () => ({
    isLimitExceeded: false
  }),
  handleSubmit: (values, { props, setSubmitting, setTouched }) => {
    const {
      formId,
      questions,
      saveForm,
      clearForms,
      submitButtonLink,
      handleSubmitError,
      pushURLHandler,
      withMailing,
      sendMailing,
      formattedAnswers,
      t
    } = props;
    // Mark all input as touched
    setTouched(
      questions.reduce(
        (acc, { name }) => ({ ...acc, [name]: true }),
        withMailing
          ? {
              recaptcha: true
            }
          : {}
      )
    );
    const answers = omit(values, 'recaptcha');
    // Reset error when submitting
    handleSubmitError(null);

    // save form in redux
    saveForm(formId, questions, answers, values.recaptcha);
    if (withMailing) {
      sendMailing({
        ...formattedAnswers, // other persisted answers (already formatted)
        ...formatAnswers(answers, questions),
        'g-recaptcha-response': values.recaptcha
      })
        .then(() => {
          // clear forms saved in redux
          clearForms();
          // push next route
          pushURLHandler(submitButtonLink);
        })
        .catch(() => {
          // mailing failed, display error
          handleSubmitError(t(DEFAULT_ERROR));
          // finish submission cycle, allows new attempt
          setSubmitting(false);
        });
    } else {
      // push next route
      pushURLHandler(submitButtonLink);
    }
  },
  validationSchema: validationSchemaFactory
});

const FormComponent = props => {
  const {
    // from creative media
    questions,
    backButtonLabel,
    backButtonTheme,
    submitButtonLabel,
    title,
    subtitle,
    submitButtonTheme,
    template,
    // from Formik
    isSubmitting,
    handleSubmit,
    status,
    persistedAnswers,
    setFieldValue,
    clearForm,
    formId,
    // from propsFormatter
    withMailing,
    // from withState
    submitError,
    handleFormRef,
    // from reactI18next
    t,
    backButtonLink,
    pushURLHandler,
    hostname,
    smartFaqUUID,
    bigram,
    faqUrlTemplate,
    formCategory
  } = props;

  useEffect(() => {
    if (persistedAnswers) {
      Object.entries(persistedAnswers).forEach(([name, value]) =>
        setFieldValue(name, value)
      );
      // after the form being hydrated we remove the persisted data
      clearForm(formId);
    }
  }, [clearForm, formId, persistedAnswers, setFieldValue]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      Object.entries(generateInitialValues(questions, template)).forEach(
        ([name, value]) => {
          setFieldValue(name, value);
        }
      );
    }
  }, [questions, setFieldValue, template]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      sessionStorage.setItem(FORM_CATEGORY, formCategory);
    }
  }, [formCategory]);

  useEffect(() => {
    window.onbeforeunload = () => {
      sessionStorage.removeItem(SAVED_FORM);
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    const suggestionFieldId = questions.reduce((acc, question) => {
      const showSuggestion = get(question, 'settings.suggestion', false);
      return showSuggestion ? question.name : acc;
    }, null);
    if (suggestionFieldId) {
      initScript(FAQ_WIDGET_URL).then(() => {
        const faqUrlParams = { smartFaqUUID, hostname, bigram };
        const faqUrl = faqUrlTemplate(faqUrlParams);
        const seeAllResultsURLParams = { hostname, bigram };
        const seeAllResultsURL = createTemplate(FAQ_ALL_RESULTS_URL_TEMPLATE)(
          seeAllResultsURLParams
        );
        const parentDOMNode = document.getElementById(
          `${suggestionFieldId}-${t(FAQ_RESULTS_ID)}`
        );
        const textInputDOMNode = document.getElementById(suggestionFieldId);
        // eslint-disable-next-line no-undef
        new SearchWidget({
          theme: '@default',
          getAlgoliaConfig: async () =>
            await axios.get(faqUrl).then(({ data }) => data),
          seeAllResultsURL,
          parentDOMNode,
          textInputDOMNode,
          debug: true,
          wording: {
            allResults: t(FAQ_ALL_RESULTS),
            noResult: t(FAQ_NO_RESULTS)
          }
        });
      });
    }
  }, [
    bigram,
    faqUrlTemplate,
    hostname,
    questions,
    setFieldValue,
    smartFaqUUID,
    t
  ]);

  const handleBack = e => {
    e.preventDefault();
    pushURLHandler(backButtonLink);
  };

  return (
    <Wrapper>
      {!isSubmitting && submitError && (
        <ApiError text={submitError} id="form_api_error" />
      )}
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <MandatoryFieldsMessage>
        {t('blocks.form.mandatoryFieldsMessage')}
      </MandatoryFieldsMessage>
      <FormContainer>
        <Form autoComplete="off" ref={handleFormRef} onSubmit={handleSubmit}>
          {questions.map(question => (
            <FieldWrapper
              key={`question_${question.name}`}
              dimension={question.dimension}
            >
              {question.type !== 'hr' ? (
                <Field
                  component={QuestionField}
                  name={question.name}
                  question={question}
                  t={t}
                />
              ) : (
                <HrSeparator />
              )}
            </FieldWrapper>
          ))}
          {(withMailing || isV2Template(template)) && (
            <FieldWrapper dimension="100">
              <RecaptchaWrapper>
                <Field component={RecaptchaField} name="recaptcha" />
              </RecaptchaWrapper>
            </FieldWrapper>
          )}
          <FieldWrapper dimension="100">
            <CTA>
              {backButtonLabel && (
                <CTAButton
                  disabled={isSubmitting}
                  onClick={handleBack}
                  theme={backButtonTheme}
                  defaultTheme={Button.themes.blackStroked}
                >
                  <Button.children.Text>{backButtonLabel}</Button.children.Text>
                </CTAButton>
              )}
              <CTAButton
                type="submit"
                disabled={isSubmitting || status.isLimitExceeded}
                loading={isSubmitting}
                theme={submitButtonTheme}
                defaultTheme={Button.themes.ctaPlain}
              >
                <Button.children.Text>{submitButtonLabel}</Button.children.Text>
              </CTAButton>
            </CTA>
          </FieldWrapper>
        </Form>
      </FormContainer>
      <RecaptchaMessage>
        This site is protected by reCAPTCHA and the Google{' '}
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </RecaptchaMessage>
    </Wrapper>
  );
};
export default compose(
  withState('submitError', 'handleSubmitError', false),
  withState('formRef', 'handleFormRef', null),
  withTranslation(),
  formikEnhancer,
  ResponsiveHoc,
  errorEnhancer
)(FormComponent);
