import find from 'lodash/find';
import get from 'lodash/get';

import formatFilesToAttachments from './formatFilesToAttachments';

export default (answers, questions) =>
  Object.entries(answers).reduce((acc, [name, value]) => {
    const associatedQuestion = find(questions, { name });

    if (associatedQuestion) {
      if (['radios', 'select'].includes(associatedQuestion.type)) {
        // if question has type radios, get the label for the unique answer
        return {
          ...acc,
          answers: {
            ...acc.answers,
            [name]: get(find(associatedQuestion.options, { value }), 'label')
          }
        };
      }
      if (associatedQuestion.type === 'checkboxes') {
        // if question has type checkboxes, get the label for all given answers
        return {
          ...acc,
          answers: {
            ...acc.answers,
            [name]: value.map(v =>
              get(find(associatedQuestion.options, { value: v }), 'label')
            )
          }
        };
      }

      if (associatedQuestion.type === 'file') {
        // if question has type file, format file with key 'attachments'
        const attachments = formatFilesToAttachments(value);
        return {
          ...acc,
          attachments
        };
      }
      // for any other question types, we return the raw answer
      return {
        ...acc,
        answers: {
          ...acc.answers,
          [name]: value
        }
      };
    }
    return acc;
  }, {});
