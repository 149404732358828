import React, { useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import uuid from 'uuid';

import { COLORS, FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import Button from 'shared/components/presentational/Button/Button';
import { ALLOWED_ATTACHMENTS_MIME_TYPES } from '../../constants';
import hasFilesExceededLimit from '../../helpers/hasFilesExceededLimit';
import {
  FieldHint,
  FormButton as BaseFormButton
} from 'shared/components/presentational/Form';

const WrapperFile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 3px;
  margin-bottom: 8px;
  border-bottom: 1px ${COLORS.alto} solid;
`;

const File = styled.p`
  width: 100%;
  margin: 0;
  color: ${COLORS.boulderBis};
  overflow: hidden;
  max-width: 75vw;
  text-overflow: ellipsis;
`;

const DeleteFileButton = styled.span`
  font-family: sans-serif;
  font-size: ${pxInRem(16)};
  color: ${COLORS.black};
  cursor: pointer;
`;

const FormButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FormButton = styled(BaseFormButton)`
  font-size: ${pxInRem(16)};
  margin-right: 8px;
  text-transform: uppercase;
`;

const NoFileChosen = styled.p`
  color: ${COLORS.boulderBis};
  font-size: ${pxInRem(14)};
  margin-bottom: 0;
`;

const FileError = styled.p`
  color: ${COLORS.guardsmanRed};
  font-family: ${FONT_FAMILIES.base};
  font-weight: ${FONT_WEIGHTS.bold};
`;

const StyledInput = styled.input`
  display: none;
`;

const propsTransformer = ({ form, field, ...props }) => ({
  form,
  field,
  ...props,
  value: '', // Input type file need to keep an empty value for security reasons
  error: form.touched[field.name] && form.errors[field.name],
  accept: ALLOWED_ATTACHMENTS_MIME_TYPES,
  onChange: data => {
    const file = data.target.files[0];
    const prevValue = props.value || [];

    if (file) {
      const fileAlreadyExist = prevValue.some(item => item.name === file.name);
      if (fileAlreadyExist) {
        // Error will disappear itself on blur
        form.setErrors({
          [field.name]: props.t('blocks.form.sameAttachmentNotAuthorized')
        });
        return;
      }

      const reader = new FileReader();
      reader.onload = item => {
        const result = item.target.result.split(',')[1];
        const id = uuid();

        const newValue = [
          ...prevValue,
          {
            id,
            name: file.name,
            size: file.size,
            mime: file.type,
            base64: result
          }
        ];

        form.setFieldValue(field.name, newValue);
        form.setTouched({ [field.name]: true });
        form.setStatus({
          isLimitExceeded: hasFilesExceededLimit(newValue)
        });
      };
      reader.readAsDataURL(file);
    }
  }
});

const FileField = props => {
  const hiddenFileInput = useRef(null);
  const { t } = useTranslation();
  const handleClick = () => {
    if (!hiddenFileInput.current) {
      return;
    }
    hiddenFileInput.current.click();
  };
  return (
    <>
      {props.status.isLimitExceeded && (
        <FileError>
          {props.t('blocks.form.attachmentSizeLimitExceeded')}
        </FileError>
      )}

      {props.field.value
        ? props.field.value.map(file => (
            <WrapperFile key={file.id}>
              <File>{file.name}</File>
              <DeleteFileButton
                onClick={() => {
                  const newValue = props.field.value.filter(
                    value => value.id !== file.id
                  );
                  props.form.setFieldValue(props.field.name, newValue);
                  props.form.setStatus({
                    isLimitExceeded: hasFilesExceededLimit(newValue)
                  });
                }}
              >
                ✕
              </DeleteFileButton>
            </WrapperFile>
          ))
        : null}

      <FieldHint hint={props.placeholder} />

      <FormButtonWrapper>
        <FormButton
          theme={Button.themes.ctaPlain}
          onClick={handleClick}
          type="button"
        >
          <Button.children.Text>
            {t('blocks.form.chooseAFile')}
          </Button.children.Text>
        </FormButton>

        {!props.field.value?.length && (
          <NoFileChosen>{t('blocks.form.noFileChosen')}</NoFileChosen>
        )}
      </FormButtonWrapper>

      <StyledInput
        id={props.name}
        name={props.name}
        type="file"
        {...propsTransformer(props)}
        ref={hiddenFileInput}
      />
    </>
  );
};

export default FileField;
