/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/*       */

import { connect } from 'react-redux';
import axios from 'axios';

import { TIMEOUT } from 'shared/modules/http/constants';
import getSmartFaqUUID from 'shared/modules/page/selectors/getSmartFaqUuid';
import getHostname from 'shared/modules/hostname/selectors/getHostname';
import getBigram from 'shared/modules/page/selectors/getCurrentZone';
import pushURLHandler from 'shared/helpers/router/pushURLHandler';
import { DECRYPT_AND_FORWARD } from 'server/constants/routes';
import { saveForm, clearForm, clearForms } from '../actions';
import Form from '../components/Form';
import { getAnswersFromFormId, getFormattedAnswers } from '../selectors';
import { saveAndPersistForm } from '../helpers/saveAndPersistFom';
import { isV2Template } from '../helpers/isV2Template';

const mapStateToProps = (
  state,
  { formId, apiUrl, mailRecipient, zone, formCategory }
) => ({
  formattedAnswers: getFormattedAnswers(state),
  persistedAnswers: getAnswersFromFormId(formId)(state),
  smartFaqUUID: getSmartFaqUUID(state),
  hostname: getHostname(state),
  bigram: getBigram(state),
  sendMailing: data => {
    return axios.post(
      DECRYPT_AND_FORWARD,
      {
        targetURL: apiUrl,
        dataToDecryptAndForward: {
          mailRecipient
        },
        data: {
          zone,
          data: data.answers,
          attachments: data.attachments,
          'g-recaptcha-response': data['g-recaptcha-response']
        }
      },
      {
        headers: Object.assign(
          {
            Accept: 'text/plain',
            'Content-Type': 'application/json'
          },
          formCategory && {
            'x-brand': formCategory
          }
        ),
        timeout: TIMEOUT.LONG
      }
    );
  }
});

const mapDispatchToProps = {
  pushURLHandler,
  clearForm,
  clearForms
};
const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,
  saveForm: isV2Template(ownProps.template) ? saveAndPersistForm : saveForm
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Form);
