export type Template = 'V1' | 'V2';

export interface Question {
  name: string;
  dimension: 50 | 100;
  position: number;
  label: string | null;
  subLabel: string | null;
  placeholder: string | null;
  isRequired: boolean;
}

interface Choice {
  label: string;
}

interface Option {
  label: string;
  value: string;
}

export enum QuestionTypeEnum {
  SELECT = 'select',
  TEXT = 'text',
  TEXTAREA = 'textarea',
  EMAIL = 'email',
  PHONE = 'phone',
  RADIOS = 'radios',
  CHECKBOXES = 'checkboxes',
  FILE = 'file',
  SEPARATOR = 'hr'
}

export interface SelectQuestion extends Question {
  type: QuestionTypeEnum.SELECT;
  settings: {
    choices: Record<string, Choice>;
  };
  options: Option[];
}
export interface SelectQuestionWithAnswer extends SelectQuestion {
  value: string;
}

export interface RadioQuestion extends Question {
  type: QuestionTypeEnum.RADIOS;
  settings: {
    choices: Record<string, Choice>;
  };
  options: Option[];
}
export interface RadioQuestionWithAnswer extends RadioQuestion {
  value: string;
}

export interface CheckboxQuestion extends Question {
  type: QuestionTypeEnum.CHECKBOXES;
  settings: {
    choices: Record<string, Choice>;
  };
  options: Option[];
  min: number;
  max: number;
}
export interface CheckboxQuestionWithAnswer extends CheckboxQuestion {
  value: string[];
}

export interface Separator extends Question {
  type: QuestionTypeEnum.SEPARATOR;
}

export interface TextQuestion extends Question {
  type: QuestionTypeEnum.TEXT;
  settings: {
    min: number;
    max: number;
  };
  options: Option[];
}
export interface TextQuestionWithAnswer extends TextQuestion {
  value: string;
}

export interface TextAreaQuestion extends Question {
  type: QuestionTypeEnum.TEXTAREA;
  settings: {
    min: number;
    max: number;
    suggestion: boolean;
  };
  options: Option[];
}
export interface TextAreaQuestionWithAnswer extends TextAreaQuestion {
  value: string;
}

export interface EmailQuestion extends Question {
  type: QuestionTypeEnum.EMAIL;
  settings: [];
  options: Option[];
}
export interface EmailQuestionWithAnswer extends EmailQuestion {
  value: string;
}

export type FileAnswer = {
  id: string;
  name: string;
  size: number;
  mime: string;
  base64: string;
};
export interface FileQuestion extends Question {
  type: QuestionTypeEnum.FILE;
  settings: [];
  options: [];
}
export interface FileQuestionWithAnswer extends FileQuestion {
  value: FileAnswer[];
}

export type AnswerableQuestionType =
  | SelectQuestion
  | RadioQuestion
  | CheckboxQuestion
  | TextQuestion
  | EmailQuestion
  | TextAreaQuestion
  | FileQuestion;

export type QuestionType = AnswerableQuestionType | Separator;

export type AnswerableQuestionTypeWithAnswer =
  | SelectQuestionWithAnswer
  | RadioQuestionWithAnswer
  | CheckboxQuestionWithAnswer
  | TextQuestionWithAnswer
  | EmailQuestionWithAnswer
  | TextAreaQuestionWithAnswer
  | FileQuestionWithAnswer;

export type QuestionTypeWithAnswer =
  | AnswerableQuestionTypeWithAnswer
  | Separator;

export type FormattedAnswers = Record<string, string | string[] | FileAnswer[]>;
